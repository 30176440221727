<template>
  <div>
    <howitworks
      :title="title"
      :img="img"
      :message="message"
      :imageurl="imageurl"
      :header="header"
      :content="content"
    ></howitworks>
  </div>
</template>

<script>
import howitworks from "@/components/extra/howitworks";

export default {
  name: "How",
  data() {
    return {
      header: "VULNERABILITY DISCLOSURE",
      title: "ABOUT VDP",
      message: `A VDP is the digital equivalent of “if you see something, say something.” Teklabspace makes it easy to get started working directly with hackers to minimize risk. We’ll help you draft your VDP using NISTrecommended practices tailored for your unique needs. We’ll then publish it and start directing friendly hackers to submit findings through our platform. Every submission will then arrive to those who need to be aware in a consistent format, and, if validated, the information will integrate seamlessly with your existing systems and workflows. `,
      img: "vdp.png",
      imageurl: "how_img.jpg",
      content: [
        {
          number: "vdp1",
          content:
            "Develop Vulnerability disclosure policy. Teklabspace recieves and disseminates vulnerability information",
        },
        {
          number: "vdp2",
          content: "Recieve vulnerability report from external source",
        },
        {
          number: "vdp3",
          content: "Acknoledgement Receipt",
        },
        {
          number: "vdp4",
          content: "Verify Report",
        },
        {
          number: "vdp5",
          content: "if No, inform Finder",
        },
        {
          number: "vdp6",
          content: "if Yes, Develop resolution",
        },
        {
          number: "vdp7",
          content: "Disseminate Advisory",
        },
        {
          number: "vdp8",
          content: "Engage in post-resolution activities",
        },
      ],
    };
  },
  components: {
    howitworks,
  },
};
</script>

<style>
</style>